
import { defineAsyncComponent, defineComponent } from 'vue'

import { ViewOrdersDriversInterface } from '@/shared/interfaces/reports/view-orders-drivers.interface'
import router from '@/router'
import { VehiclesActions } from '@/store/modules/vehicles/actions'
import { DarkStoresActions } from '@/store/modules/darkstores/actions'

export default defineComponent({
  components: {
    SearchInput: defineAsyncComponent(() => import('@/shared/components/SearchInput.vue')),
    BaseIcon: defineAsyncComponent(() => import('@/shared/components/base-icon/BaseIcon.vue')),
    FilterPanel: defineAsyncComponent(() => import('@/shared/components/filter/FilterPanel.vue')),
    PageTitle: defineAsyncComponent(() => import('@/shared/components/PageTitle.vue')),
    DynamicTable: defineAsyncComponent(() => import('@/shared/components/dynamic-table/DynamicTable.vue'))
  },
  data () {
    return {
      searchValue: null as any,
      showFilterModal: false,
      tableFields: [
        { id: 'orderId', name: 'Order Number', width: '15%', sortable: false, currentSort: false, sort: null, sortName: 'order_id', type: 'link' },
        { id: 'username', name: 'Username', width: '15%', sortable: false, currentSort: false, sort: null, sortName: 'username' },
        { id: 'driverFullName', name: 'Driver Full Name', width: '15%', sortable: false, currentSort: false, sort: null, sortName: 'driver_full_name' },
        { id: 'orderTotal', name: 'Order Total', width: '15%', sortable: true, currentSort: false, sort: null, sortName: 'order_total' },
        { id: 'orderCreatedAt', name: 'Order Date', width: '15%', sortable: true, currentSort: false, sort: null, sortName: 'order_created_at' },
        { id: 'userAddress', name: 'User Address', width: '15%', sortable: false, currentSort: false, sort: null, sortName: 'user_address' },
        { id: 'customerCode', name: 'Customer Code', width: '15%', sortable: false, currentSort: false, sort: null, sortName: 'customer_code' },
        { id: 'orderBinNumber', name: 'Order Bin Number', width: '15%', sortable: false, currentSort: false, sort: null, sortName: 'order_bin_number' },
        { id: 'customerPhoneNumber', name: 'Customer Phone Number', width: '15%', sortable: false, currentSort: false, sort: null, sortName: 'customer_phone_number' },
        { id: 'darkstore', name: 'Darkstore', width: '15%', sortable: false, currentSort: false, sort: null },
        { id: 'destinationLatitude', name: 'Destination Latitude', width: '15%', sortable: false, currentSort: false, sort: null, sortName: 'destination_latitude' },
        { id: 'destinationLongitude', name: 'Destination Longitude', width: '15%', sortable: false, currentSort: false, sort: null, sortName: 'destination_longitude' },
        { id: 'driverId', name: 'Driver Id', width: '15%', sortable: false, currentSort: false, sort: null, sortName: 'driver_id' },
        { id: 'failedDeliveryReasonId', name: 'Failed Delivery Reason Id', width: '15%', sortable: false, currentSort: false, sort: null, sortName: 'failed_delivery_reason_id' },
        { id: 'id', name: 'id', width: '15%', sortable: false, currentSort: false, sort: null, sortName: 'id' },
        { id: 'modifiedAt', name: 'Modified At', width: '15%', sortable: false, currentSort: false, sort: null, sortName: 'modified_at' },
        { id: 'driverLicenseNo', name: 'Driver License No', width: '15%', sortable: false, currentSort: false, sort: null, sortName: 'driver_license_no' },
        { id: 'orderStatus', name: 'Order Status', width: '15%', sortable: false, currentSort: false, sort: null, sortName: 'order_status' },
        { id: 'preparingStatusDatetime', name: 'Preparing Status Datetime', width: '15%', sortable: false, currentSort: false, sort: null, sortName: 'preparing_status_datetime' },
        { id: 'preparingStatusLatitude', name: 'Preparing Status Latitude', width: '15%', sortable: false, currentSort: false, sort: null, sortName: 'preparing_status_latitude' },
        { id: 'preparingStatusLongitude', name: 'Preparing Status Longitude', width: '15%', sortable: false, currentSort: false, sort: null, sortName: 'preparing_status_longitude' },
        { id: 'assignedStatusDatetime', name: 'Assigned Status Datetime', width: '15%', sortable: false, currentSort: false, sort: null, sortName: 'assigned_status_datetime' },
        { id: 'assignedStatusLatitude', name: 'Assigned Status Latitude', width: '15%', sortable: false, currentSort: false, sort: null, sortName: 'assigned_status_latitude' },
        { id: 'assignedStatusLongitude', name: 'Assigned Status Longitude', width: '15%', sortable: false, currentSort: false, sort: null, sortName: 'assigned_status_longitude' },
        { id: 'readyForPickupStatusDatetime', name: 'Ready For Pickup Status Datetime', width: '15%', sortable: false, currentSort: false, sort: null, sortName: 'ready_for_pickup_status_datetime' },
        { id: 'readyForPickupStatusLatitude', name: 'Ready For Pickup Status Latitude', width: '15%', sortable: false, currentSort: false, sort: null, sortName: 'ready_for_pickup_status_latitude' },
        { id: 'readyForPickupStatusLongitude', name: 'Ready For Pickup Status Longitude', width: '15%', sortable: false, currentSort: false, sort: null, sortName: 'ready_for_pickup_status_longitude' },
        { id: 'pickedUpStatusDatetime', name: 'Picked Up Status Datetime', width: '15%', sortable: false, currentSort: false, sort: null, sortName: 'picked_up_status_datetime' },
        { id: 'pickedUpStatusLatitude', name: 'Picked Up Status Latitude', width: '15%', sortable: false, currentSort: false, sort: null, sortName: 'picked_up_status_latitude' },
        { id: 'pickedUpStatusLongitude', name: 'Picked Up Status Longitude', width: '15%', sortable: false, currentSort: false, sort: null, sortName: 'picked_up_status_longitude' },
        { id: 'onTheWayStatusDatetime', name: 'On The Way Status Datetime', width: '15%', sortable: false, currentSort: false, sort: null, sortName: 'on_the_way_status_datetime' },
        { id: 'onTheWayStatusLatitude', name: 'On The Way Status Latitude', width: '15%', sortable: false, currentSort: false, sort: null, sortName: 'on_the_way_status_latitude' },
        { id: 'onTheWayStatusLongitude', name: 'On The Way Status Longitude', width: '15%', sortable: false, currentSort: false, sort: null, sortName: 'on_the_way_status_longitude' },
        { id: 'atTheAddressStatusDatetime', name: 'At The Address Status Datetime', width: '15%', sortable: false, currentSort: false, sort: null, sortName: 'at_the_address_status_datetime' },
        { id: 'atTheAddressStatusLatitude', name: 'At The Address Status Latitude', width: '15%', sortable: false, currentSort: false, sort: null, sortName: 'at_the_address_status_latitude' },
        { id: 'atTheAddressStatusLongitude', name: 'At The Address Status Longitude', width: '15%', sortable: false, currentSort: false, sort: null, sortName: 'at_the_address_status_longitude' },
        { id: 'deliveredStatusDatetime', name: 'Delivered Status Datetime', width: '15%', sortable: false, currentSort: false, sort: null, sortName: 'delivered_Status_datetime' },
        { id: 'deliveredStatusLatitude', name: 'Delivered Status Latitude', width: '15%', sortable: false, currentSort: false, sort: null, sortName: 'delivered_status_latitude' },
        { id: 'deliveredStatusLongitude', name: 'Delivered Status Longitude', width: '15%', sortable: false, currentSort: false, sort: null, sortName: 'delivered_status_longitude' },
        { id: 'delayedStatusDatetime', name: 'Delayed Status Datetime', width: '15%', sortable: false, currentSort: false, sort: null, sortName: 'delayed_status_datetime' },
        { id: 'delayedStatusLatitude', name: 'Delayed Status Latitude', width: '15%', sortable: false, currentSort: false, sort: null, sortName: 'delayed_status_latitude' },
        { id: 'delayedStatusLongitude', name: 'Delayed Status Longitude', width: '15%', sortable: false, currentSort: false, sort: null, sortName: 'delayed_status_longitude' },
        { id: 'canceledStatusDatetime', name: 'Canceled Status Datetime', width: '15%', sortable: false, currentSort: false, sort: null, sortName: 'canceled_status_datetime' },
        { id: 'canceledStatusLatitude', name: 'Canceled Status Latitude', width: '15%', sortable: false, currentSort: false, sort: null, sortName: 'canceled_status_latitude' },
        { id: 'canceledStatusLongitude', name: 'Canceled Status Longitude', width: '15%', sortable: false, currentSort: false, sort: null, sortName: 'canceled_status_longitude' },
        { id: 'toReturnStatusDatetime', name: 'To Return Status Datetime', width: '15%', sortable: false, currentSort: false, sort: null, sortName: 'to_return_status_datetime' },
        { id: 'toReturnStatusLatitude', name: 'To Return Status Latitude', width: '15%', sortable: false, currentSort: false, sort: null, sortName: 'to_return_status_latitude' },
        { id: 'toReturnStatusLongitude', name: 'To Return Status Longitude', width: '15%', sortable: false, currentSort: false, sort: null, sortName: 'to_return_status_longitude' },
        { id: 'toReturnAssignedStatusDatetime', name: 'To Return Assigned Status Datetime', width: '15%', sortable: false, currentSort: false, sort: null, sortName: 'to_return_assigned_status_datetime' },
        { id: 'toReturnAssignedStatusLatitude', name: 'To Return Assigned Status Latitude', width: '15%', sortable: false, currentSort: false, sort: null, sortName: 'to_return_assigned_status_latitude' },
        { id: 'toReturnAssignedStatusLongitude', name: 'To Return Assigned Status Longitude', width: '15%', sortable: false, currentSort: false, sort: null, sortName: 'to_return_assigned_status_longitude' },
        { id: 'returnedStatusDatetime', name: 'Returned Status Datetime', width: '15%', sortable: false, currentSort: false, sort: null, sortName: 'returned_status_datetime' },
        { id: 'returnedStatusLatitude', name: 'Returned Status Latitude', width: '15%', sortable: false, currentSort: false, sort: null, sortName: 'returned_status_latitude' },
        { id: 'returnedStatusLongitude', name: 'Returned Status Longitude', width: '15%', sortable: false, currentSort: false, sort: null, sortName: 'returned_status_longitude' },
        { id: 'zoneName', name: 'Zone', width: '15%', sortable: false, currentSort: false, sort: null, sortName: '' },
        { id: 'trackingId', name: 'Tracking Id', width: '15%', sortable: false, currentSort: false, sort: null, sortName: '' }
      ],
      filters: [
        { name: this.$t('filters.orderTotal'), model: 'orderTotal', type: 'text' },
        { name: this.$t('filters.orderId'), model: 'orderId', type: 'text' },
        { name: this.$t('filters.createdAt'), model: 'createdAt', type: 'date', calendarType: 'range' },
        {
          name: this.$t('filters.vehicleType'),
          model: 'vehicleTypeId',
          dataLoading: true,
          fetchDataOnExpand: true,
          dataRequested: false,
          actionName: 'vehicleTypes',
          type: 'radio',
          data: []
        },
        {
          name: this.$t('filters.darkstores'),
          model: 'darkstore_Ids',
          type: 'checkbox',
          dataLoading: true,
          fetchDataOnExpand: true,
          dataRequested: false,
          actionName: 'fetchDarkstore',
          data: []
        },
        { name: this.$t('filters.trackingId'), model: 'trackingId', type: 'number' }
        // { name: this.$t('filters.username'), model: 'username', type: 'text' },
        // { name: this.$t('filters.createdAt'), model: 'createdAt', type: 'date', calendarType: 'range' },
        // { name: this.$t('filters.userAddress'), model: 'userAddress', type: 'text' },
        // { name: this.$t('filters.customerCode'), model: 'customerCode', type: 'text' },
        // { name: this.$t('filters.binNo'), model: 'binNo', type: 'text' }
      ],
      selectedReport: this.reportType
    }
  },
  methods: {
    menuEvent (e: any): void {
      console.log(e)
    },
    clickSort (field: any): void {
      this.tableFields = this.tableFields?.map((el: any) => {
        if (el.id === field.id) {
          el.currentSort = true
          if (el.sort === null) {
            el.sort = 'asc'
          } else if (el.sort === 'asc') {
            el.sort = 'desc'
          } else if (el.sort === 'desc') {
            el.sort = null
          }
        } else {
          el.currentSort = false
          el.sort = null
        }
        return el
      })
      router.push({ query: { ...this.$route.query, orderBy: field.sortName, sort: field.sort } })
    },
    onRedirectToOrder (item: any): void {
      router.push({ path: '/orders/all', query: { id: item.orderId } })
    },
    onExpand (event: any): void {
      if (event === 'vehicleTypes') {
        this.$store.dispatch(`vehicles/${[VehiclesActions.FETCH_VEHICLE_TYPES]}`)
      }
      if (event === 'fetchDarkstore') {
        this.$store.dispatch(`darkstores/${DarkStoresActions.FETCH_DARKSTORES}`, { pageNUmber: 1, pageSize: 200 })
      }
    }
  },
  watch: {
    darkstoresAsDictionary: {
      immediate: true,
      handler (val) {
        if (!val.length) return
        const target = this.filters[4]
        target.dataRequested = true
        target.data = val
        target.dataLoading = false
      }
    },
    searchValue (val) {
      const queries = this.$route.query
      queries.pageNumber = '1'
      router.push({ query: { ...queries, username: this.searchValue } })
    },
    vehicleTypes: {
      immediate: true,
      handler (val) {
        if (!val.length) return
        const target = this.filters[3]
        target.dataRequested = true
        target.data = val
        target.dataLoading = false
      }
    },
    $route: {
      immediate: true,
      handler (val: any) {
        if (val.query.orderBy) {
          this.tableFields = this.tableFields.map((el: any) => {
            if (el.id === val.query.orderBy) {
              el.currentSort = true
              el.sort = val.query.sort
            }
            return el
          })
        }
      }
    },
    selectedReport (val) {
      if (val) {
        router.push({ query: { reportName: val.name } })
      }
    }
  },
  props: {
    reportType: {
      type: String
    }
  },
  computed: {
    darkstoresAsDictionary () {
      return this.$store.getters['darkstores/getDarkStoresAsDictionary']
    },
    vehicleTypes (): any[] {
      return this.$store.getters['vehicles/getVehicleTypes']
        .map((el: any) => {
          return {
            id: el.id,
            label: el.value
          }
        })
    },
    reports (): ViewOrdersDriversInterface[] {
      return this.$store.getters['reports/getViewOrderDriverReports']
    },
    exportReports () {
      return this.$store.getters['reports/getViewOrderDriverExportReports']
    },
    pagination () {
      return this.$store.getters['reports/getViewOrderDriverPagination']
    },
    loading (): boolean {
      return this.$store.getters['reports/getLoading']
    }
  }
})

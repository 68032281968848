import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "pt-14 pb-5 px-5" }
const _hoisted_2 = { class: "flex justify-between" }
const _hoisted_3 = { class: "flex jus" }
const _hoisted_4 = { class: "relative h-42 w-288 me-3" }
const _hoisted_5 = { class: "w-full" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { class: "px-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageTitle = _resolveComponent("PageTitle")!
  const _component_SearchInput = _resolveComponent("SearchInput")!
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_FilterPanel = _resolveComponent("FilterPanel")!
  const _component_DynamicTable = _resolveComponent("DynamicTable")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_PageTitle, { title: "reports.ordersAndDriversTitle" })
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_SearchInput, {
            placeholder: _ctx.$t('placeholders.username'),
            modelValue: _ctx.searchValue,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchValue) = $event))
          }, null, 8, ["placeholder", "modelValue"])
        ]),
        _createElementVNode("button", {
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showFilterModal = true)),
          class: "border border-inputBorder w-42 h-42"
        }, [
          _createElementVNode("span", _hoisted_5, [
            _createVNode(_component_BaseIcon, { name: "filter" })
          ])
        ]),
        (_ctx.showFilterModal)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createVNode(_component_FilterPanel, {
                filters: _ctx.filters,
                onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showFilterModal = false)),
                onFetchData: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onExpand($event)))
              }, null, 8, ["filters"])
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createVNode(_component_DynamicTable, {
        tableData: _ctx.reports,
        exportData: _ctx.exportReports,
        loading: _ctx.loading,
        reportType: _ctx.reportType,
        pagination: _ctx.pagination,
        tableFields: _ctx.tableFields,
        scrollable: true,
        exportFields: [
        { id: 'customerCode', name: 'Customer Code' },
        { id: 'customerPhoneNumber', name: 'Customer Phone Number' },
        { id: 'darkstore', name: 'Darkstore' },
        { id: 'destinationLatitude', name: 'Destination Latitude' },
        { id: 'destinationLongitude', name: 'Destination Longitude' },
        { id: 'driverId', name: 'Driver Id' },
        { id: 'driverFullName', name: 'Driver Full Name' },
        { id: 'driverLicenseNo', name: 'Driver License No' },
        { id: 'failedDeliveryReasonId', name: 'Failed Delivery Reason Id' },
        { id: 'id', name: 'Id' },
        { id: 'modifiedAt', name: 'Modified At' },
         { id: 'orderBinNumber', name: 'Order Bin Number' },
        { id: 'orderCreatedAt', name: 'Order Created At' },
        { id: 'orderId', name: 'Order Id' },
        { id: 'orderStatus', name: 'Order Status' },
        { id: 'orderTotal', name: 'Order Total' },
        { id: 'userAddress', name: 'User Address' },
        { id: 'username', name: 'Username' },
        { id: 'preparingStatusDatetime', name: 'Preparing Status Datetime' },
        { id: 'preparingStatusLatitude', name: 'Preparing Status Latitude' },
        { id: 'preparingStatusLongitude', name: 'Preparing Status Longitude' },
          { id: 'assignedStatusDatetime', name: 'Assigned Status Datetime' },
        { id: 'assignedStatusLatitude', name: 'Assigned Status Latitude' },
        { id: 'assignedStatusLongitude', name: 'Assigned Status Longitude' },
        { id: 'readyForPickupStatusDatetime', name: 'Ready For Pickup Status Datetime' },
        { id: 'readyForPickupStatusLatitude', name: 'Ready For Pickup Status Latitude' },
        { id: 'readyForPickupStatusLongitude', name: 'Ready For Pickup Status Longitude' },
        { id: 'pickedUpStatusDatetime', name: 'Picked Up Status Datetime' },
        { id: 'pickedUpStatusLatitude', name: 'Picked Up Status Latitude' },
        { id: 'pickedUpStatusLongitude', name: 'Picked Up Status Longitude' },
        { id: 'onTheWayStatusDatetime', name: 'On The Way Status Datetime' },
        { id: 'onTheWayStatusLatitude', name: 'On The Way Status Latitude' },
        { id: 'onTheWayStatusLongitude', name: 'On The Way Status Longitude' },
        { id: 'atTheAddressStatusDatetime', name: 'At The Address Status Datetime' },
        { id: 'atTheAddressStatusLatitude', name: 'At The Address Status Latitude' },
        { id: 'atTheAddressStatusLongitude', name: 'at The Address Status Longitude' },
        { id: 'deliveredStatusDatetime', name: 'Delivered Status Datetime' },
        { id: 'deliveredStatusLatitude', name: 'Delivered Status Latitude' },
        { id: 'deliveredStatusLongitude', name: 'Delivered Status Longitude' },
         { id: 'delayedStatusDatetime', name: 'Delayed Status Datetime' },
        { id: 'delayedStatusLatitude', name: 'Delayed Status Latitude' },
        { id: 'delayedStatusLongitude', name: 'Delayed Status Longitude' },
        { id: 'canceledStatusDatetime', name: 'Canceled Status Datetime' },
        { id: 'canceledStatusLatitude', name: 'Canceled Status Latitude' },
        { id: 'canceledStatusLongitude', name: 'Canceled Status Longitude' },
        { id: 'toReturnStatusDatetime', name: 'To Return Status Datetime' },
        { id: 'toReturnStatusLatitude', name: 'To Return Status Latitude' },
        { id: 'toReturnStatusLongitude', name: 'To Return Status Longitude' },
        { id: 'toReturnAssignedStatusDatetime', name: 'To Return Assigned Status Datetime' },
        { id: 'toReturnAssignedStatusLatitude', name: 'To Return Assigned Status Latitude' },
        { id: 'toReturnAssignedStatusLongitude', name: 'To Return Assigned Status Longitude' },
        { id: 'returnedStatusDatetime', name: 'Returned Status Datetime' },
        { id: 'returnedStatusLatitude', name: 'Returned Status Latitude' },
        { id: 'returnedStatusLongitude', name: 'Returned Status Longitude' },
        { id: 'zoneName', name: 'Zone' },
        { id: 'trackingId', name: 'Tracking Id' }
      ],
        onSort: _cache[4] || (_cache[4] = ($event: any) => (_ctx.clickSort($event))),
        buttons: { edit: true, delete: true, export: true},
        onMenuEvent: _cache[5] || (_cache[5] = ($event: any) => (_ctx.menuEvent($event))),
        onGetReports: _cache[6] || (_cache[6] = ($event: any) => (_ctx.$emit('getReports', $event))),
        onNavigateToLink: _cache[7] || (_cache[7] = ($event: any) => (_ctx.onRedirectToOrder($event)))
      }, null, 8, ["tableData", "exportData", "loading", "reportType", "pagination", "tableFields"])
    ])
  ], 64))
}